import React, { Component } from "react";
import './About.css'
import { Center, Grid } from '@chakra-ui/react'
import { Text } from '@chakra-ui/react'
import { Stack, HStack, VStack, Box, Heading, StackDivider} from '@chakra-ui/react'


function Feature({ title, desc, ...rest }) {
    return (
      <Box p={5} shadow='md' borderWidth='1px' {...rest}>
        <Heading fontSize='xl'>{title}</Heading>
        <Text mt={4}>{desc}</Text>
      </Box>
    )
}
  
function StackEx() {
    return (
      <VStack
      divider={<StackDivider borderColor='gray' />}
      spacing={4}
      align='stretch'
    >

        <Feature
        title='Nedir?'
        desc="Dolarlamaas.com, Türkiye'den çalışabileceğiniz döviz ile maaş veren şirketlerin 
        listesini sunan kişisel bir website projesidir. Kur dengesizliğinden kaçınmak 
        isteyenler için önemli bir kaynak olarak karşılıksız olarak sunulmaktadır. "
        />
        <Feature
        title='Neye Yarar?'
        desc="Dövizle maaaş almak isteyenlerin, kendilerine en uygun şirketi ve iş ilanlarını 
        bulmalarına yardımcı olur."
        />
        <Feature
        title='Neleri İçerir?'
        desc="Şirketlerin profilleri, sektörü, iş ilan sayfaları, ne zaman listeye eklendiği, 
        ilgili şirkette çalışan biri tarafından onaylanıp onaylanmadığı gibi ekstra bilgileri 
        içermektedir."
        />
        <Feature
        title='Nasıl Katkı Sağlarım?'
        desc="Listeye eklenmesinin uygun olduğunu düşündüğünüz şirketleri aşağıdaki Twitter DM linki üzerinden iletebilirsiniz."
        />

    </VStack>

    )
}


class About extends Component {
      

    render() {
        
        return (
            <div>
            <StackEx></StackEx>
            </div>
        );
    }
}

export default About;
