import React, { Component } from "react";
import './SimpleTable.css'
import { Center } from '@chakra-ui/react'


function truncate(source, size) {
    return source.length > size ? source.slice(0, size - 1) + "…" : source;
}

class SimpleTable extends Component {

    render() {
        let jsonData = require('./data.json'); 
        const items = [];
        let counter = 0;

        for (const item of jsonData.reverse()) {
            counter += 1;
            let jobs = item['İş İlanları']
            let jobs_html = (jobs.length < 3) ? "." 
                    : <a href={jobs}>{truncate(jobs, 25)}</a>

            items.push(
                <tr>
                    <td scope="row" data-label="Şirket"> {item['Şirket']} </td>
                    <td data-label="Lokasyon"> {item['Lokasyon']} </td>
                    <td data-label="İş İlanları"> {jobs_html} </td>
                    <td data-label="Sektör"> {item['Sektör']} </td>
                    <td data-label="Eklenme Tarihi"> {item['Eklenme Tarihi']} </td>
                    <td data-label="Notlar"> {item['Notlar']} </td>
                    <td data-label="Onaylandı"> {item['Onaylandı']} </td>
                    <td data-label="Sıra">{counter}</td>
                </tr>)
            
        }
        return (
            <Center bg='green'>   
            <table>
                <caption>Türkiye'de Dövizle Maaş Veren Şirketler</caption>
                <thead>
                    <tr>
                    <th scope="col">Şirket</th>
                    <th scope="col">Lokasyon</th>
                    <th scope="col">İş İlanları</th>
                    <th scope="col">Sektör</th>
                    <th scope="col">Eklenme Tarihi</th>
                    <th scope="col">Notlar</th>
                    <th scope="col">Onaylandı</th>
                    <th scope="col">Sıra</th>
                    </tr>
                </thead>
                <tbody>
                    {items}
                </tbody>
            </table>
            </Center>
        );
    }
}

export default SimpleTable;

// ReactDOM.render(<SuperTable />, document.getElementById("superTable"));