import React from 'react';
import '../App.css';
import './AirtableTable.css'
import { Link, Center, Text } from '@chakra-ui/react'

function Iframe(props) {
    return (<div dangerouslySetInnerHTML={ {__html:  props.iframe?props.iframe:""}} />);
}

const AirtableTable = () => {
  
  const iframe = '<iframe class="airtable-embed" src="https://airtable.com/embed/shrEfCSMfYO7dLr1E?backgroundColor=green&viewControls=on" frameborder="0" onmousewheel="" width="85%" height="700" style="border: 1px solid #ccc;"></iframe>'; 
  return (
    <div className="App">
      <Center bg='green' h='100px' color='white'>
        <Text textAlign={[ 'left', 'center' ]}> 
          💵💵 Türkiye'de dövizle maaş veren şirketler 💵💵
        </Text>
      </Center>
      <p align="center"><Iframe iframe={iframe} /> </p>
      <Center>
      <table>
        <thead>
          <tr>
            <th>Code</th>
            <th>Company</th>
            <th class="numeric">Price</th>
            <th class="numeric">Change</th>
            <th class="numeric">Change %</th>
            <th class="numeric">Open</th>
            <th class="numeric">High</th>
            <th class="numeric">Low</th>
            <th class="numeric">Volume</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>AAC</td>
            <td>AUSTRALIAN AGRICULTURAL COMPANY LIMITED.</td>
            <td class="numeric">$1.38</td>
            <td class="numeric">-0.01</td>
            <td class="numeric">-0.36%</td>
            <td class="numeric">$1.39</td>
            <td class="numeric">$1.39</td>
            <td class="numeric">$1.38</td>
            <td class="numeric">9,395</td>
          </tr>
          <tr>
            <td>AAC</td>
            <td>AUSTRALIAN AGRICULTURAL COMPANY LIMITED.</td>
            <td class="numeric">$1.38</td>
            <td class="numeric">-0.01</td>
            <td class="numeric">-0.36%</td>
            <td class="numeric">$1.39</td>
            <td class="numeric">$1.39</td>
            <td class="numeric">$1.38</td>
            <td class="numeric">9,395</td>
          </tr>
          <tr>
            <td>AAC</td>
            <td>AUSTRALIAN AGRICULTURAL COMPANY LIMITED.</td>
            <td class="numeric">$1.38</td>
            <td class="numeric">-0.01</td>
            <td class="numeric">-0.36%</td>
            <td class="numeric">$1.39</td>
            <td class="numeric">$1.39</td>
            <td class="numeric">$1.38</td>
            <td class="numeric">9,395</td>
          </tr>
        </tbody>
      </table>
      </Center>
      <Center bg="green" h='100px' color='white'>
        <Text> Öneriler için Twitter DM
          <Center bg='green' h='30px' color='white'>
            <Link color='teal.500' isExternal='true' href='https://twitter.com/messages/compose?recipient_id=274153627'>
                  @yfenes
            </Link>
          </Center>
        </Text>
      </Center>
    </div>
  );

}

export default AirtableTable;