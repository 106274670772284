import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import AirtableTable from './components/AirtableTable';
import SuperTable from './components/SuperTable';
import SimpleTable from './components/SimpleTable';
import About from './components/About';
import { Link, Center, Text } from '@chakra-ui/react'


function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path='/' exact component={SimpleTable} />
          {/* <Route path='/sirketler' component={AirtableTable} />   */}
          {/* <Route path='/sirketler' component={SuperTable} />   */}
          <Route path='/about' component={About} />  
        </Switch>
        <Center bg="green" h='100px' color='white'>
        <Text> Öneriler için Twitter DM
          <Center bg='green' h='30px' color='white'>
            <Link color='teal.500' isExternal='true' href='https://twitter.com/messages/compose?recipient_id=274153627'>
                  @yfenes
            </Link>
          </Center>
        </Text>
      </Center>
      </Router>
    </>
  );
}

export default App;
