import React, { Component } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
// import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import './SuperTable.css'


class SuperTable extends Component {

    render() {
        let jsonData = require('./data.json'); 
        console.log(jsonData);
        const items = []

        for (const item of jsonData) {
            items.push(
                <Tr>
                    <Td> {item['Şirket']} </Td>
                    <Td> {item['Lokasyon']} </Td>
                    <Td> {item['İş İlanları']} </Td>
                    <Td> {item['Sektör']} </Td>
                    <Td> {item['Eklenme Tarihi']} </Td>
                    <Td> {item['Notlar']} </Td>
                    <Td> {item['Onaylandı']} </Td>
                </Tr>)
        }
        console.log(items)
        return (
            <div className="responsiveTable">
            <Table class="responsiveTable">
                <Thead>
                    <Tr>
                        <Th>Şirket</Th>
                        <Th>Lokasyon</Th>
                        <Th>İş İlanları</Th>
                        <Th>Sektör</Th>
                        <Th>Eklenme Tarihi</Th>
                        <Th>Notlar</Th>
                        <Th>Onaylandı</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {items}
                </Tbody>
            </Table>
            </div>
        );
    }
}

export default SuperTable;

// ReactDOM.render(<SuperTable />, document.getElementById("superTable"));